import React from "react" 
import { useTranslation } from "gatsby-plugin-react-i18next"

import { imgCompMap } from "./imgMap"
import StepsToProtectBusiness from "./StepsToProtectBusiness"
import useHindiLangFont from "../../hooks/useHindiLangFont"

const StepsToProtectBusinessMLP = () => {
  const {t} = useTranslation("stepsToProtectBusiness")
  const {hindiBold, hindiRegular} = useHindiLangFont()

  const data = [
    {
      image: imgCompMap.enterDetails,
      title: t("EnterDetails"),
      desc: t("EnterDetailsDesc")
    },
    {
      image: imgCompMap.scheduleCall,
      title: t("ScheduleCall"),
      desc: t("ScheduleCallDesc")
    },
    {
      image: imgCompMap.talkToAdvisor,
      title: t("TalkToAdvisor"),
      desc: t("TalkToAdvisorDesc")
    },
    {
      image: imgCompMap.chooseInsurer,
      title: t("ChooseInsurer"),
      desc: t("ChooseInsurerDesc")
    },
    {
      image: imgCompMap.protectBusiness,
      title: t("Protectbusiness"),
      desc: t("ProtectbusinessDesc")
    },
  ]

  return (
    <StepsToProtectBusiness title={t('Title')} titleStyle={{textTransform: "uppercase"}} data={data} titleClass={hindiBold} textClass={hindiRegular} />
  )
}

export default StepsToProtectBusinessMLP