import { StaticImage } from "gatsby-plugin-image";
import React from "react"

export const imgCompMap = {
  enterDetails: <StaticImage 
    src="../../assets/images/steps-to-protect-business/enter-details.webp"
    alt="enter-details"
    loading="lazy"
    width={145}
    height={107}
  />,
  scheduleCall: <StaticImage 
    src="../../assets/images/steps-to-protect-business/schedule-call.webp"
    alt="schedule-call"
    loading="lazy"
    width={145}
    height={107}
  />,
  talkToAdvisor: <StaticImage 
    src="../../assets/images/steps-to-protect-business/talk-to-advisor.webp"
    alt="talk-to-advisor"
    loading="lazy"
    width={145}
    height={107}
  />,
  chooseInsurer: <StaticImage 
    src="../../assets/images/steps-to-protect-business/choose-insurer.webp"
    alt="choose-insurer"
    loading="lazy"
    width={145}
    height={107}
  />,
  protectBusiness: <StaticImage 
    src="../../assets/images/steps-to-protect-business/protect-your-business.webp"
    alt="protect-your-business"
    loading="lazy"
    width={145}
    height={107}
  />
}