import React from "react"
import styled from "styled-components"
import { Section, SectionWrapper, SubTitle, Text } from "../core/commonExports"

const Card = ({data}) => {
  return (
    <CardContainer>
      <ImgContainer>
        {data.image}
      </ImgContainer>
      <div style={{textAlign: "center"}}>
        <Text fontSize="20px" mfontSize="16px" lineHeight="25px" mlineHeight="20px" fontWeight="bold">
          {data.title}
        </Text>
        <Text fontSize="16px" mfontSize="12px" lineHeight="20px" mlineHeight="15px" fontWeight="500">
          {data.desc}
        </Text>
      </div>
    </CardContainer>
  )
}

const StepsToProtectBusiness = ({
  data=[], 
  title="", 
  // for hindi text
  titleStyle={},
  titleClass="", 
  textClass="",
  style={},
  desktopStyles={}, 
  mobileStyles={}
}) => {
  return (
    <Section background="#FEF7F4" style={style} desktopStyles={desktopStyles} mobileStyles={mobileStyles}>
      <SectionWrapper desktopStyles={{padding: "80px 0"}}>
        <SubTitle className={titleClass} fontSize="32px" mfontSize="24px" lineHeight="38px" mlineHeight="36px" fontWeight="700" style={{textAlign: "center", ...titleStyle}}>
          {title}
        </SubTitle>
        <StepsContainer>
          {data?.map((step, stepNo) => (
            <StepContainer>
              <StepNum>{stepNo + 1}</StepNum>
              <Card data={step} textClass={textClass} />
            </StepContainer>
          ))}
          {data.length ? <DottedLine /> : null}
        </StepsContainer>
      </SectionWrapper>
    </Section>
  )
}

export default StepsToProtectBusiness

const DottedLine = styled.div`
  position: absolute; 
  top: 1.25rem; 
  border: 1px dashed #B3B3B3; 
  width: 976px; 
  z-index: -1;

  @media screen and (max-width: 1200px) {
    top: 2rem; 
  }

  @media screen and (max-width: 768px) {
    display: block;
    top: auto;
    left: 2rem;
    width: 0;
    height: 620px;
  }
`

const StepsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 12px;
  padding-bottom: 1rem;
  margin-top: 54px;

  @media screen and (max-width: 1200px) {
    overflow: auto;
    padding: 1rem 2.5rem;
    padding-left: 40vh;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: fit-content;
    margin: auto;
    padding: 1rem;
    gap: 54px;
  }
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 8px;
  }
`

const StepNum = styled.p`
  width: 42px;
  height: 42px;
  text-align: center;
  margin: 0;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  background: #57BA70;

  @media screen and (max-width: 768px) {
    width: 32px;
    height: 32px;
    font-size: 16px;
    line-height: 20px;
  }
`

const CardContainer = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 234px;
  height: 276px;
  padding: 20px 10px;

  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    width: 239px;
    height: 93px;
    padding: 10px;
  }
`

const ImgContainer = styled.div`
  width: 145px;
  height: 107px;

  @media screen and (max-width: 768px) {
    flex-shrink: 0;
    width: 60px;
    height: 44px;
  }
`